import { i18n } from '@/i18n'
import { getCardType } from '@/utils/tools/card'
import { nextTick } from 'vue'
import {
  ERROR_CODE_CARD_NUMBER_EMPTY,
  ERROR_CODE_DATE_EMPTY,
  ERROR_CODE_SECURITY_CODE_EMPTY,
  ERROR_CODE_SECURITY_CODE_ERROR,
  ERROR_CODE_YEAR_DATE_ERROR
} from '@/enums/error-code'
import { isEmptyValue } from '@/utils/tools/utils'

export function initFormData(form, data) {
  if (data.billing) {
    for (const item in data.billing) {
      form[item] = data.billing[item]
      if (item === 'firstName') {
        form.cardFirstName = data.billing[item]
      } else if (item === 'lastName') {
        form.cardLastName = data.billing[item]
      }
    }
  }
}

export function getCheckoutData(formData, method) {
  const submitData = {
    browser: {
      userAgent: window.navigator.userAgent,
      javaEnabled: window.navigator.javaEnabled(),
      colorDepth: window.screen.colorDepth,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      timeZoneOffset: new Date().getTimezoneOffset().toString()
    }
  }
  if (method.cardName === 'CARD') {
    submitData.method = 'CreditCard'
    submitData.card = {
      expiredYear: formData.expiredDate.split('/')[1],
      expiredMonth: formData.expiredDate.split('/')[0],
      cardNumber: formData.cardNumber.replace(/\D/g, ''),
      cardholder: `${formData.cardFirstName} ${formData.cardLastName}`,
      cvv: formData.cvv
    }
  } else {
    submitData.method = method.code
  }
  submitData.extension = formData.extension
  submitData.billingAddress = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    document: formData.document,
    country: formData.country,
    email: formData.email,
    city: formData.city,
    address: formData.address,
    streetNumber: formData.streetNumber,
    phone: formData.phone,
    state: formData.state,
    zipCode: formData.zipCode
  }
  return submitData
}

// const luhn = (cardNumber) => {
//   let total = 0
//   const cardNumberLength = cardNumber.length
//   const maxLength = cardNumberLength + 1
//   for (let i = 1; i < maxLength; i++) {
//     let num = parseInt(cardNumber.substring(cardNumberLength - i, cardNumberLength - i + 1))
//     if (i % 2 === 0) {
//       num *= 2
//       if (num < 10) {
//         total += num
//       } else {
//         const t1 = num + ''
//         total += parseInt(t1.substring(0, 1)) + parseInt(t1.substring(1, 2))
//       }
//     } else {
//       total += num
//     }
//   }
//   return total % 10 === 0
// }
/**
 * 当卡号进行编辑的时候执行,用于控制输入框的输入规则
 * @param evt
 * @param callback
 */
export const onCardInput = (evt, callback) => {
  const selectionStart = evt.target.selectionStart
  let formattedNumber = evt.target.value.replace(/\D/g, '')
  formattedNumber = formattedNumber.replace(/(.{4})/g, '$1 ').trim()
  nextTick(() => {
    let s = 0
    if (evt.data === null && selectionStart % 5 === 0) {
      // 删除光标位置不变
      s = 0
    } else if (evt.data !== null && !/^\d$/.test(evt.data)) {
      // 输入非数字光标位置-1
      s = -1
    } else if (selectionStart % 5 === 0) {
      // 碰到空格光标位置+1
      s = 1
    }
    evt.target.selectionStart = evt.target.selectionEnd = selectionStart + s
    if (callback) {
      callback(formattedNumber, getCardType(formattedNumber))
    }
  })
}

/**
 * 当卡到期日期进行编辑的时候执行,用于控制输入框的输入规则
 * @param event
 * @returns {*}
 */
export const onExpiredDateInput = (event) => {
  const value = event.target.value
  if (event.inputType === 'deleteContentBackward') {
    if (value && value[value.length - 1] === '/') {
      return value.replaceAll('/', '')
    }
    return value
  }
  // 把除了了'/'和数字的其他字符去掉
  let currentValue = value.replace(/[^\d/]/g, '')
  // 用户自己输入'/'
  if (currentValue.includes('/')) {
    const array = currentValue.split('/')
    array.length = 2
    array[0] = array[0].slice(0, 2)
    array[1] = array[1].slice(0, 2)
    if (array[0] === '' && array[1] === '') {
      currentValue = currentValue.replaceAll('/', '')
    } else if (array[0].length === 1 && (array[1] === '' || (+array[0] >= 2 && +array[0] <= 9))) {
      array[0] = `0${array[0]}`
      currentValue = array.join('/')
    } else {
      currentValue = array.join('/')
    }
  }
  // 用户直接输入数字没有输入'/'
  if (currentValue.length > 0) {
    const dateArray = currentValue.split('')
    if (dateArray[0] === '0' && dateArray[1] === '0') {
      dateArray[1] = 1
      currentValue = dateArray.join('')
    }
    if (dateArray.length === 1 && +dateArray[0] >= 2 && +dateArray[0] <= 9) {
      currentValue = `0${dateArray[0]}/`
    } else if (dateArray.length >= 2) {
      if (+`${dateArray[0]}${dateArray[1]}` > 12) {
        dateArray[0] = '1'
        dateArray[1] = '2'
      }
      if (dateArray.every((i) => i !== '/')) {
        dateArray.splice(2, 0, '/')
        if (dateArray.length > 5) {
          dateArray.length = 5
        }
      }
      currentValue = dateArray.join('')
    }
  }
  return currentValue
}

/**
 * 当安全码进行编辑的时候执行,用于控制输入框的输入规则
 * @param event
 * @returns {*}
 */
export const onSecurityCodeInput = (event) => {
  return event.target.value.replace(/[^\d]/g, '')
}

export const rules = {
  cardNumber: function (rule, value, callback) {
    // 卡号
    if (isEmptyValue(value)) {
      callback(
        new Error(i18n.t('message.common.inputPlaceholder', { field: i18n.t('message.checkout.cardNumber').toLowerCase() })),
        ERROR_CODE_CARD_NUMBER_EMPTY
      )
      return
    }
    // const num = value.replace(/\s*/g, '');
    // const validate = luhn(num);
    // if (!validate) {
    //   callback(new Error(i18n.t('message.checkout.correctCardNumberTip')), ERROR_CODE_CARD_NUMBER_ERROR);
    // } else {
    //
    // }
    callback()
  },
  monthYear: function (rule, value, callback) {
    // 卡有效期
    if (isEmptyValue(value)) {
      callback(
        new Error(i18n.t('message.common.inputPlaceholder', { field: i18n.t('message.checkout.expiredDate').toLowerCase() })),
        ERROR_CODE_DATE_EMPTY
      )
      return
    }
    const val = value
    const n = new Date()
    const y = n.getFullYear()
    const m = n.getMonth() + 1
    if (val.indexOf('/') > 1) {
      const myArray = val.split('/')
      if (myArray[0].length < 2 || myArray[1].length < 2) {
        callback(new Error(i18n.t('message.checkout.correctDateTip')), ERROR_CODE_YEAR_DATE_ERROR)
        return
      }
      const month = Number.parseInt(myArray[0])
      const year = Number.parseInt(myArray[1])
      if (y % 100 > year) {
        // 年份比现在大
        callback(new Error(i18n.t('message.checkout.correctDateTip')), ERROR_CODE_YEAR_DATE_ERROR)
      } else if (y % 100 === year && m > month) {
        // 月份比现在小
        callback(new Error(i18n.t('message.checkout.correctDateTip')), ERROR_CODE_YEAR_DATE_ERROR)
      } else {
        callback()
      }
    } else {
      // 内容未填写正确
      callback(new Error(i18n.t('message.checkout.correctDateTip')), ERROR_CODE_YEAR_DATE_ERROR)
    }
  },
  cvv: function (rule, value, callback) {
    if (isEmptyValue(value)) {
      callback(
        new Error(i18n.t('message.common.inputPlaceholder', { field: i18n.t('message.checkout.cvv').toLowerCase() })),
        ERROR_CODE_SECURITY_CODE_EMPTY
      )
      return
    }
    const len = value.length
    if (len >= 3) {
      // 正确
      callback()
    } else {
      // 长度不对
      callback(new Error(i18n.t('message.checkout.correctSecurityCodeTip')), ERROR_CODE_SECURITY_CODE_ERROR)
    }
  }
}
